import { RouteStationAPI } from "@/api/route-station";
import { GetZonesResponse } from "@/types/response/route";
import { defineStore } from "pinia";

interface ZonesStateStore {
    zones : GetZonesResponse[],
    zoneSelected:  GetZonesResponse | null
}

const routeStationAPI = new RouteStationAPI();

export const useZoneStore = defineStore("zones", {
    state: (): ZonesStateStore => ({
        zones: [],
        zoneSelected : null
    }),
    actions: { 
        async getZones(){
            const zones = await routeStationAPI.getZones() as any;
            this.zones = zones;
        },
        setZone(zone: GetZonesResponse){
            this.zoneSelected = zone
        }
    }
})