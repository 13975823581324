
import { LoggerApi } from "@/api/logs";
import { formatDateNoonTimestamp } from "@/filters/formatDate";
import { getDayString, getErrorCatch } from "@/utils";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "ErrorsTrips"
})
export default class ErrorsTrips extends Vue {
    private logApi = new LoggerApi()
    public days: Date[] = [];
    mounted(): void {
        this.getLogs()
    }
    async getLogs(dayInit?:number, dayFinish?: number){
        try {
            this.logApi.getLoggerErrorTrips(dayInit,dayFinish)
        } catch (error) {
            getErrorCatch(error)
        }
    }
    setDateFilter() {
        this.$nextTick(() => {
            this.days[0].setHours(12)
            this.days[1].setHours(12)
            const dayInit = formatDateNoonTimestamp(this.days[0].getTime()).toMillis()
            const dayFinish = formatDateNoonTimestamp(this.days[1].getTime()).toMillis()
            this.getLogs(dayInit,dayFinish)
        })
    }
}
