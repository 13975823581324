
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { GetTripResponse } from '@/types/response/trip';
import { TypeCancelTrip } from '@/types/request/trip';
import { formatCurrency, getErrorCatch, requiredField } from '@/utils';
import { useMainStore } from '@/store/modules/main';
import { FormVuetify, SelectData } from '@/types/common/vuetify';
import { PlanningTripAPI } from '@/api/planning';
import { GetStationsByActiveTripResponse, STATUS_ACTIVE_TRIP } from '@/types/response/planning';
import { ResponseCommonName } from '@/types/common/api';
import { TripApi } from '@/api/trip';
import { useTripStore } from '@/store/modules/trips';

@Options({
    name: 'ModalCancelTrip',
    components: { ModalCommon },
    props: {
        tripSelected: {
            type: Object,
            default: () => ({}),
            required: false
        },
        isVisible: {
            type: Boolean,
            default: false,
            required: true
        },
        newsTrips: {
            type: Boolean,
            default: false,
            required: true
        }
    }
})
export default class ModalCancelTrip extends Vue {
    private mainStore = useMainStore();
    private planningTripAPI = new PlanningTripAPI()
    private tripApi = new TripApi()
    public tripSelected!: GetTripResponse;
    public newsTrips !: boolean;
    public isVisible!: boolean
    public successCoupon = false;
    public couponName = "";
    public isLoadingChangeRoute = false;
    public formatCurrency = formatCurrency;
    public amountFinal = 0;
    public typeCancel: TypeCancelTrip | null = 'BOTH';
    public isBoth = false;
    public onlyOneCancel = false;
    public newOrigin: string | null = null
    public newDestination: string | null = null
    public requiredField = requiredField;
    public isEditOrigin = false;
    public isEditDestination = false;
    public stationsGeneral: GetStationsByActiveTripResponse[] = []
    public stationsGeneralReturn: GetStationsByActiveTripResponse[] = []
    private tripStore = useTripStore();
    public activeTrip: 'outbound' | 'return' = 'outbound';
    public isCancelInit = false;
    public isCancelReturn = false;
    public isLoadingRoute = false;

    async getStations() {
        if (!this.tripSelected) {
            return;
        }
        try {
            this.stationsGeneral = await this.planningTripAPI.getStationsByIdActiveTrip(this.tripSelected.activeTrip._id)
            if (this.tripSelected.typeTrip == 'round' && this.tripSelected.returnDataActiveTrip) {
                this.stationsGeneralReturn = await this.planningTripAPI.getStationsByIdActiveTrip(this.tripSelected.returnDataActiveTrip._id)
            }
        } catch (error) {
            getErrorCatch(error)
        }
    }

    get stationOriginTripID() {
        const stations = this.activeTrip == 'outbound' ? this.stationsGeneral : this.stationsGeneralReturn
        const stationFilter = this.activeTrip == 'outbound' ? this.tripSelected.stationOrigin : this.tripSelected.stationDestination;
        return stations.find(({ originStation }) => originStation == stationFilter)?.idOriginStation || null
    }

    get stationDestinationTripID() {
        const stations = this.activeTrip == 'outbound' ? this.stationsGeneral : this.stationsGeneralReturn
        const stationFilter = this.activeTrip == 'outbound' ? this.tripSelected.stationDestination : this.tripSelected.stationOrigin;
        return stations.find(({ finalStation }) => finalStation == stationFilter)?.idFinalStation || null
    }

    get stationOrigin() {
        const stations = (this.activeTrip == 'outbound' ? this.stationsGeneral : this.stationsGeneralReturn).filter((s) => {
            return s.idFinalStation == this.stationDestinationTripID
        }).map((station) => {
            return {
                _id: station.idOriginStation,
                name: station.originStation + ` (${station.hourInit})`
            }
        })
        const mapStation = new Map();
        stations.forEach((obj) => mapStation.set(obj._id, obj));
        return Array.from<ResponseCommonName>(mapStation.values()).sort((a, b) => a.name.localeCompare(b.name)).filter(({ name }) => name.split("(")[0].trim() !== this.tripSelected.stationOrigin)
    }

    get stationDestination() {
        const stations = (this.activeTrip == 'outbound' ? this.stationsGeneral : this.stationsGeneralReturn).filter((s) => {
            return s.idOriginStation == this.stationOriginTripID
        }).map((station) => {
            return {
                _id: station.idFinalStation,
                name: station.finalStation + ` (${station.hourFinish})`
            }
        })
        const mapStation = new Map();
        stations.forEach((obj) => mapStation.set(obj._id, obj));
        return Array.from<ResponseCommonName>(mapStation.values()).sort((a, b) => a.name.localeCompare(b.name)).filter(({ name }) => name.split("(")[0].trim() !== this.tripSelected.stationDestination)
    }

    mounted(): void {
        this.$watch('isVisible', (value: boolean) => {
            if (value) {
                if(this.tripSelected){
                    const typeCancelTrip = this.tripSelected.typeCancelTrip || [];
                    if(typeCancelTrip.includes('BOTH')){
                        this.closeCancelModal()
                        return;
                    }
                    if(typeCancelTrip.includes('ONE_WAY')){
                        this.activeTrip = 'return';
                        this.isCancelInit = true;
                    }
                    if(typeCancelTrip.includes('ONLY_RETURN')){
                        this.isCancelReturn = true;
                    }
                    if(typeCancelTrip.includes('ONLY_RETURN') && typeCancelTrip.includes('ONE_WAY')){
                        this.closeCancelModal()
                        return;
                    }
                    if(this.tripSelected.isLate){
                        this.activeTrip = 'return';
                        this.isCancelInit = true;
                        if(this.tripSelected.isLateReturn){
                            this.closeCancelModal()
                            return;
                        }
                        return;
                    }
                    if(this.tripSelected.isLateReturn){
                        this.isCancelReturn = true;
                        return;
                    }
                    let isLateActive = this.tripSelected.activeTrip.status !== STATUS_ACTIVE_TRIP.ON_HOLD
                    if(this.tripSelected.typeTrip == 'justReturn'){
                        if(isLateActive){
                            this.activeTrip = 'return';
                            this.isCancelInit = true;
                            return;
                        }
                    }else{
                        const isLateReturn = this.tripSelected.returnDataActiveTrip?.status !== STATUS_ACTIVE_TRIP.ON_HOLD
                        if(isLateActive){
                            if(isLateReturn){
                                this.closeCancelModal()
                                return;
                            }
                            this.activeTrip = 'return';
                            this.isCancelInit = true;
                            return;
                        }
                        if(isLateReturn){
                            this.isCancelReturn = true;
                            return;
                        }
                    }
                }
                this.getStations()
            }
        })
    }


    get isRound() {
        if (this.tripSelected) {
            return this.tripSelected.typeTrip == 'round'
        }
        return false
    }

    closeCancelModal() {
        this.couponName = '';
        this.successCoupon = false;
        this.amountFinal = 0
        this.resetChange('all')
        this.$emit('closeModal');
        this.activeTrip = 'outbound'
        this.isCancelInit = false;
        this.isCancelReturn = false;
    }
    copy() {
        navigator.clipboard.writeText(this.couponName);
        this.mainStore.setNotification({
            isOpen: true,
            message: 'Cupón copiado correctamente',
            color: 'blue darken-2'
        })
    }
    resetChange(type: 'origin' | 'destination' | 'all') {
        if (type == 'origin') {
            this.isEditOrigin = false
            this.newOrigin = null;
        }
        if (type == 'destination') {
            this.newDestination = null
            this.isEditDestination = false
        }
        if (type == 'all') {
            this.resetChange('origin');
            this.resetChange('destination')
        }
    }
    async setStation(type: 'origin' | 'destination') {
        if (!this.tripSelected) {
            return
        }
        const form = type == 'origin' ? this.$refs['formOrigin'] as FormVuetify : this.$refs['formDestination'] as FormVuetify;
        const isValid = (await form.validate()).valid;
        if (!isValid) {
            return;
        }
        const idOrigin = this.newOrigin == null ? this.stationOriginTripID : this.newOrigin
        const idDestination = this.newDestination == null ? this.stationDestinationTripID : this.newDestination
        this.isLoadingRoute = true
        try {
            if (idOrigin !== null && idDestination !== null) {
                await this.tripApi.setStationTrip(this.tripSelected._id, idOrigin, idDestination, this.activeTrip == 'return')
                await this.tripStore.getTripsCustomer(this.newsTrips);
                this.$emit('updateTripSelected', this.tripSelected._id)
                this.resetChange(type)
                this.mainStore.setNotification({
                    isOpen: true,
                    color: 'green-darken-2',
                    message: 'Ruta actualizada'
                })
            } else {
                throw new Error('Error con el origen y destino')
            }
        } catch (error) {
            getErrorCatch(error)
        }
        this.isLoadingRoute = false
    }

    setActiveTrip(active: 'outbound' | 'return' = 'outbound') {
        this.activeTrip = active;
    }
}
