
import { Options, Vue } from 'vue-class-component';
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useRoutesStore } from '@/store/modules/route'
import { formatDateCompleted } from '@/filters/formatDate';
import { getErrorCatch } from '@/utils';
import ModalCommon from '../common/ModalCommon.vue';
import { RouteStationAPI } from '@/api/route-station'
import { useZoneStore } from '@/store/modules/zones';

@Options({
    name: 'ZoneTable',
    components: {
        ModalCommon
    }
})
export default class ZoneTable extends Vue {
    private routesStore = useRoutesStore();
    public zoneStore = useZoneStore();
    private routeAPI = new RouteStationAPI();
    public formatDateCompleted = formatDateCompleted;
    public isVisibleModalDelete = false;
    public loadingDelete = false;
    public loadingTable = false;
    public zoneDelete = '';
    public zoneIDDelete = ''
    public serverOptions: ServerOptions = {
        page: 1,
        rowsPerPage: 5
    }

    public headers: Header[] = [
        { text: "Ruta", value: "name" },
        { text: 'Acciones', value: 'actions' }
    ];

    get items() {
        return this.zoneStore.zones
    }

    setZone(_id: string) {
        const zoneSelected = this.items.find((item) => item._id == _id);
        if(zoneSelected){
           this.zoneStore.setZone(zoneSelected);
        }
    }

    removeZone(_id: string, nameRoute: string) {
        this.zoneDelete = nameRoute;
        this.zoneIDDelete = _id;
        this.isVisibleModalDelete = true;
    }

    closeModalDelete() {
        this.isVisibleModalDelete = false;
        this.zoneDelete = '';
        this.zoneIDDelete = ''
    }
    async deleteZone() {
        this.loadingDelete = true;
        try {
            await this.routeAPI.deleteZone(this.zoneIDDelete);
            await this.zoneStore.getZones();
            this.closeModalDelete();
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingDelete = false;
    }
    setEditable(id: string) {
        this.routesStore.setEditableRoute(id);
    }
    async saveEditable(id: string, value: string) {
        this.routesStore.setLoadingRoute(id, true)
        try {
            await this.routeAPI.editNameRoute(id, value)
            await this.routesStore.getRoutes();
        } catch (error) {
            getErrorCatch(error)
        }
        this.routesStore.setLoadingRoute(id, false)
    }
    resetEditable(id: string) {
        this.routesStore.resetEditableRoute(id)
    }
}
