import { defineStore } from "pinia";
import {
  CrudEntity,
  IPagination,
  ResponseCommonName,
} from "@/types/common/api";
import { RouteStationAPI } from "@/api/route-station";
import {
  GetRouteByIDResponse,
  GetRoutesResponse,
  GetStationsSearch,
} from "@/types/response/route";

export interface StationCrud extends ResponseCommonName, CrudEntity {}

interface RoutesStateStore {
  routes: GetRoutesResponse[];
  paginationRoute: IPagination;
  totalRoutes: number;
  routeSelected?: GetRouteByIDResponse;
  stationsList: StationCrud[];
  loadingRoutes: boolean;
  stationsSearch: GetStationsSearch[]
}

const routeStationAPI = new RouteStationAPI();

export const useRoutesStore = defineStore("routes", {
  // other options...
  state: (): RoutesStateStore => ({
    routes: [],
    paginationRoute: {
      limit: 5,
      page: 1,
    },
    totalRoutes: 1,
    routeSelected: undefined,
    stationsList: [],
    loadingRoutes: false,
    stationsSearch : []
  }),
  actions: {
    setLoadingRoutes(value: boolean) {
      this.loadingRoutes = value;
    },
    async getStationSearch(){
      const stations = await routeStationAPI.getStations() as GetStationsSearch[];
      this.stationsSearch = stations.map((station) => {
        return {
          ...station,
          destinations : station.destinations.filter((item,index,self) => self.findIndex(obj => obj.name === item.name) === index)
        }
      })
    },
    async getRoutes() {
      const { items, total } = await routeStationAPI.getRoutes({
        limit: this.paginationRoute.limit,
        page: this.paginationRoute.page,
      });
      this.routes = items.map((route) => {
        return {
          ...route,
          crudRoute: {
            newValueEditable: route.nameRoute,
            editable: false,
            loading: false,
          },
        };
      });
      this.totalRoutes = total;
    },
    async getStations() {
      const stations = await routeStationAPI.getStations();
      this.stationsList = stations.map((station) => {
        return {
          ...station,
          editable: false,
          newValueEditable: station.name,
        };
      });
    },
    resetEditableRoute(id: string) {
      this.routes = this.routes.map((route) => {
        if (route._id === id) {
          return {
            ...route,
            crudRoute: {
              ...route.crudRoute,
              editable: false,
              newValueEditable: route.nameRoute,
            },
          };
        }
        return { ...route };
      });
    },
    resetEditable(id: string) {
      this.stationsList = this.stationsList.map((route) => {
        if (route._id === id) {
          return {
            ...route,
            editable: false,
            newValueEditable: route.name,
          };
        }
        return { ...route };
      });
    },
    setLoadingRoute(id : string,value : boolean){
      this.routes = this.routes.map((route) => {
        if (route._id === id) {
          return {
            ...route,
            crudRoute: {
              ...route.crudRoute,
              loading : value
            },
          };
        }
        return { ...route };
      });
    },
    setEditableRoute(id: string) {
      this.routes = this.routes.map((route) => {
        if (route._id === id) {
          return {
            ...route,
            crudRoute: {
              ...route.crudRoute,
              editable: !route.crudRoute.editable,
            },
          };
        }
        return { ...route };
      });
    },
    setEditableStation(id: string) {
      this.stationsList = this.stationsList.map((route) => {
        if (route._id === id) {
          return {
            ...route,
            editable: !route.editable,
          };
        }
        return { ...route };
      });
    },
    setPage(page: number) {
      this.paginationRoute.page = page;
    },
    setLimit(limit: number) {
      this.paginationRoute.limit = limit;
    },
    async getRoute(routeID: string) {
      this.deleteRouteSelected();
      const data = await routeStationAPI.getRoute(routeID);
      this.routeSelected = data;
    },
    deleteRouteSelected() {
      this.routeSelected = undefined;
    },
    getStationById(route : string){
      return this.stationsList.find(({_id})=> _id === route);
    }
  },
});
