import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-grow-1 py-2" }
const _hoisted_2 = { class: "d-flex align-start py-3" }
const _hoisted_3 = { class: "text-h4 secondary--text text-secondary" }
const _hoisted_4 = {
  class: "text-helvetica h-regular",
  style: {"max-width":"400px"}
}
const _hoisted_5 = {
  class: "text-helvetica h-regular",
  style: {"max-width":"400px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_EasyDataTable = _resolveComponent("EasyDataTable")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, "Lista de logs " + _toDisplayString(_ctx.sortDate), 1)
      ]),
      _createVNode(_component_v_spacer)
    ]),
    _createVNode(_component_v_card, { class: "mt-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "d-flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "py-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_select, {
                      modelValue: _ctx.levelFilter,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.levelFilter) = $event)),
                      label: "Level",
                      variant: "outlined",
                      density: "comfortable",
                      items: _ctx.itemsLevel
                    }, null, 8, ["modelValue", "items"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      label: "Descripción",
                      variant: "outlined",
                      density: "comfortable"
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.uidFilter,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.uidFilter) = $event)),
                      label: "UID",
                      variant: "outlined",
                      density: "comfortable"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      onClick: _ctx.getLogs,
                      variant: "elevated",
                      color: "green"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Filtrar")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              icon: "mdi-reload",
              size: "x-small",
              variant: "text",
              onClick: _ctx.reload
            }, null, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_EasyDataTable, {
              loading: _ctx.loadingTable,
              headers: _ctx.headers,
              items: _ctx.items,
              "server-items-length": _ctx.total,
              "server-options": _ctx.serverOptions,
              "onUpdate:serverOptions": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.serverOptions) = $event)),
              "buttons-pagination": ""
            }, {
              "item-description": _withCtx(({ description }) => [
                _createElementVNode("p", _hoisted_4, _toDisplayString(description), 1)
              ]),
              "item-codeMessage": _withCtx(({ codeMessage }) => [
                _createElementVNode("p", _hoisted_5, _toDisplayString(codeMessage), 1)
              ]),
              "item-dateCreated": _withCtx(({ dateCreated }) => [
                _createTextVNode(_toDisplayString(_ctx.formatDateCompleted(dateCreated)), 1)
              ]),
              _: 1
            }, 8, ["loading", "headers", "items", "server-items-length", "server-options"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}