import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d flex-column w-100 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainDriver = _resolveComponent("MainDriver")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user?.role === 'DRIVER' || _ctx.user?.role == 'ADMIN')
      ? (_openBlock(), _createBlock(_component_MainDriver, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}