export enum TRIP_ENDPOINTS {
    CREATE_TRIP = 'create-trip',
    CREATE_TRIP_AUTH = 'create-trip-auth/',
    CREATE_TRIP_MASSIVE = 'create-trip-massive/',
    GET_TRIP = 'get-trip/',
    GET_TRIPS = 'get-trips',
    CREATE_TRIP_ADMIN = 'create-trip-admin',
    RESEND_EMAIL = 'resend-email-trip/',
    CANCEL_TRIP = 'cancel-trip/',
    VALIDATE_GPS_TRIP = 'validate-gps/',
    GET_PASSENGERS_TRIPS = '/passengers',
    SET_STATION_TRIP = 'set-station-trip/'
}