
import { getErrorCatch } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import type { Header, ServerOptions } from 'vue3-easy-data-table';
import { LoggerApi } from '@/api/logs'
import { formatDateMS } from '@/filters/formatDate';
import { SelectData } from '@/types/common/vuetify';
@Options({
    name: 'LoggerView',
})
export default class LoggerView extends Vue {
    private loggerApi = new LoggerApi()
    public formatDateCompleted = formatDateMS;
    public loadingTable = false;
    public uidFilter = '';
    public levelFilter = '';
    public sortDate = 'desc'
    public itemsLevel: SelectData[] = [{
        title: 'Todos',
        value: 'ALL'
    },{
        title: 'Info',
        value: 'INFO'
    }, {
        title: 'Error',
        value: 'ERROR'
    }]
    public headers: Header[] = [
        { text: "Fecha", value: "dateCreated", sortable : true },
        { text: "UID", value: "uid" },
        { text: "Tipo", value: "level" },
        { text: "Descripcion", value: "description" },
        { text: "Crudo", value: "codeMessage" },
        { text: 'Origen', value: 'origin' },

    ];
    public items = [];
    public total = 0;
    public limit = 5;
    public page = 1;
    public interval = 0
    public value = false;

    public serverOptions: ServerOptions = {
        page: 1,
        rowsPerPage: 5
    }

    created() {
        this.$watch('serverOptions', ({ page, rowsPerPage,sortBy,sortType }: ServerOptions) => {
            if(sortBy == 'dateCreated'){
                this.sortDate = sortType as string
            }
            this.page = page;
            this.limit = rowsPerPage;
            this.getLogs();
        })
    }

    mounted(): void {
        this.getLogs()
    }

    async getLogs() {
        this.loadingTable = true;
        try {
            
            const { items, total } = await this.loggerApi.getLoggers(this.page, this.limit,{level : this.levelFilter, uid: this.uidFilter, sortDate : this.sortDate});
            this.total = total;
            this.items = items;
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingTable = false
    }
    reload() {
        this.getLogs()
    }

    beforeUnmount(): void {
        clearInterval(this.interval);
    }

    executeInterval(interval = 10000) {
        this.interval = setInterval(() => {
            console.info("Ejecutando reload ")
            this.reload();
        }, interval)
    }
    setInterval(value: any) {
        if (value) {
            this.executeInterval()
        } else {
            clearInterval(this.interval);
        }
    }
}
