
import { Options, Vue } from 'vue-class-component';
import ZoneTable from '../components/zone/ZoneTable.vue';
import { useRoutesStore } from '@/store/modules/route';
import CreateZone from '@/components/zone/CreateZone.vue';
import { getErrorCatch } from '@/utils';
import type { Header } from 'vue3-easy-data-table';
import { useZoneStore } from '@/store/modules/zones';

@Options({
  name: 'RutasView',
  components: {
    ZoneTable,
    CreateZone
  }
})
export default class RutasView extends Vue {
  public routeStore = useRoutesStore();
  public zoneStore = useZoneStore();
  public visibleCreateStation = false;
  public visibleCreateRoute = false;

  get loading() {
    return this.routeStore.loadingRoutes;
  }

  public headers: Header[] = [
    { text: '#', value: 'keyOrder' },
    { text: "Origen", value: "originStation.name" },
    { text: 'Destino', value: 'finalStation.name' },
    { text: 'Duración(min)', value: 'duration' }
  ];
  async mounted() {
    try {
      await this.routeStore.getStations();
      await this.zoneStore.getZones();
    } catch (error) {
      getErrorCatch(error)
    }
  }
}
