import { IResponsePagination } from "@/types/common/api";
import API from "@/utils/request";
import { AxiosInstance } from "axios";

export class LoggerApi {
  private request = new API("logger");
  private api: AxiosInstance = this.request.getInstance();

  async getLoggers(page : number, limit : number, dataReq : {uid?:string, level?:string, sortDate : string}) {
    const URL = `/?page=${page}&limit=${limit}`;
    const dataFinal : any = {
      sortDate : dataReq.sortDate
    };
    if(dataReq.level){
      dataFinal.level = dataReq.level !== 'ALL' ?  dataReq.level : ''
    }
    if(dataReq.uid){
      dataFinal.uid = dataReq.uid.trim()
    }
    const { data } = await this.api.post<IResponsePagination<[]>>(URL,dataFinal);
    return data;
  }

  async getLoggerErrorTrips(dayInit?:number,dayFinish?:number){
    let URL = "trips-errors";
    if(dayInit){
      URL+=`?fromDate=${dayInit}`
    }
    if(dayFinish){
      URL+=`&toDate=${dayFinish}`
    }
    const { data } = await this.api.get(URL);
    return data;
  }
}
