import { defineStore } from "pinia";
import { IPagination, ResponseCommonUsers } from "@/types/common/api";
import {
  GetBookingResponse,
  GetCalendarResponse,
  PlanningTripsResponse,
} from "@/types/response/planning";
import { PlanningTripAPI } from "@/api/planning";
import { CustomerSeating, GetBookingRequest } from "@/types/request/planning";
import { UserApi } from "@/api/users";
import { VehicleAPI } from "@/api/vehicles";
import { AllVehiclePatentsResponse } from "@/types/response/vehicle";
import { getDaysInRange } from "@/utils";

export type STEP = "PASSENGERS" | "PAYMENT" | "CONFIRM" | "EMPTY";
export type STAGE_STEPS = "outbound" | "return";

interface PlanningStateStore {
  planningTrips: PlanningTripsResponse[];
  totalPlanningTrips: number;
  paginationPlanningTrips: IPagination;
  tripsSelected: GetBookingResponse[];
  tripsSelectedFinal: GetBookingResponse[];
  searchSelected?: GetBookingRequest;
  tripSelected?: GetBookingResponse;
  tripFinalSelected?: GetBookingResponse;
  passengers: CustomerSeating[];
  passengersFinal?: CustomerSeating[];
  owner?: CustomerSeating;
  ownerFinal?: CustomerSeating;
  step: STEP;
  calendarData: GetCalendarResponse[];
  drivers: ResponseCommonUsers[];
  isLoadingBooking: boolean;
  emptyTrips?: boolean;
  stageTrip: STAGE_STEPS;
  stageSeatings: STAGE_STEPS;
  vehicles: AllVehiclePatentsResponse[]
  buyMassive?: boolean
  daySelectedMassive?: string;
  daySelectedSeating?: string;
  selectedAllDays?: boolean;
  selectedAllSeating?: boolean;
  tripsSelecteds: GetBookingResponse[];
  rangeDatesMassive: {
    day: string,
    month: string,
    disabled: boolean
  }[],
  datePlanningCalendar:number
}

const planningTripAPI = new PlanningTripAPI();
const userApi = new UserApi();
const vehicleApi = new VehicleAPI();

export const usePlanningStore = defineStore("planning", {
  // other options...
  state: (): PlanningStateStore => ({
    planningTrips: [],
    totalPlanningTrips: 0,
    paginationPlanningTrips: {
      limit: 5,
      page: 1,
    },
    tripsSelected: [],
    tripsSelectedFinal: [],
    searchSelected: undefined,
    tripSelected: undefined,
    tripFinalSelected: undefined,
    passengers: [],
    owner: undefined,
    step: "EMPTY",
    calendarData: [],
    drivers: [],
    vehicles: [],
    isLoadingBooking: false,
    emptyTrips: undefined,
    stageTrip: "outbound",
    stageSeatings: 'outbound',
    passengersFinal: [],
    ownerFinal: undefined,
    buyMassive: false,
    daySelectedMassive: '',
    selectedAllDays: false,
    selectedAllSeating: false,
    tripsSelecteds: [],
    daySelectedSeating: '',
    rangeDatesMassive: [],
    datePlanningCalendar : new Date().getTime()
  }),
  actions: {
    setRangeDatesMassive(dates: number[]) {
      const range = getDaysInRange(dates).map((day) => {
        return {
          ...day,
          disabled: !this.getTripsActiveDay(day.day)
        }
      });
      this.rangeDatesMassive = range;

    },
    setDaySelectedSeating(daySeating: string) {
      this.daySelectedSeating = daySeating
    },
    removeTripSelecteds(code: number) {
      this.tripsSelecteds = this.tripsSelecteds.filter((trip) => trip.code !== code)
    },
    addTripSelecteds(trip: GetBookingResponse) {
      if (this.tripsSelecteds.find(({ code }) => {
        return code == trip.code
      })) {
        // exists;
        if (trip.code !== null) {
          this.removeTripSelecteds(trip.code);
        }
        return;
      }
      const sameDay = this.tripsSelecteds.find(({ dateExecuting }) => {
        return dateExecuting.split("T")[0] === trip.dateExecuting.split("T")[0]
      });
      if (sameDay && sameDay.code !== null) {
        // same day
        this.removeTripSelecteds(sameDay.code);
      }
      this.tripsSelecteds.push({ ...trip, code: new Date().getTime() })
      //this.nextDaySelectedMassive();
    },
    resetTripsSelecteds() {
      this.tripsSelecteds = []
    },
    setSelectedAllDays(value: boolean) {
      this.selectedAllDays = value
      this.tripsSelecteds = []
    },
    setSelectedAllSeatings(value: boolean) {
      if (!value && this.tripsSelecteds.length > 0) {
        const first = this.tripsSelecteds[0].dateExecuting.split('T')[0].split("-")[2]
        this.daySelectedSeating = `${first}`;
      }
      this.tripsSelecteds = this.tripsSelecteds.map((trip) => {
        return {
          ...trip,
          seatingSelected: undefined
        }
      })
      this.selectedAllSeating = value;
    },
    setDaySelectedMassive(day: string) {
      this.daySelectedMassive = day;
    },
    setSeatingTripMassive(seating: string, day: string, remove = false) {
      const trips = this.tripsSelecteds.map((trip) => {
        const dateExe = trip.dateExecuting.split("T")[0].split("-")[2]
        if (dateExe == day) {
          let seatingExists = trip.seatingSelected || []
          if (remove) {
            seatingExists = seatingExists.filter((seatingEx) => seatingEx !== seating)
          } else {
            seatingExists.push(seating)
          }
          return {
            ...trip,
            seatingSelected: seatingExists
          }
        }
        return trip
      })
      this.tripsSelecteds = trips;
    },
    getTripsActiveDay(day: string) {
      return this.tripsSelected.filter(({ dateExecuting }) => {
        return dateExecuting.split('T')[0].split('-')[2] == day
      }).length > 0
    },
    nextDaySelectedMassive() {
      if (!this.searchSelected || this.daySelectedMassive == '') {
        return
      }
      const date = this.searchSelected.date
      const finalDates = date as number[]
      const dates = getDaysInRange(finalDates).map(({ day }) => day).filter((day) => this.getTripsActiveDay(day));
      const daySelected = parseInt(this.daySelectedMassive as string);
      if (isNaN(daySelected)) {
        return;
      }
      const nextDay = (daySelected + 1).toString();
      if (dates.includes(nextDay)) {
        this.setDaySelectedMassive(nextDay);
      }
    },
    setBuyMultiple(value: boolean) {
      this.buyMassive = value;
      this.tripsSelected = [];
      this.tripsSelectedFinal = [];
    },
    setDataPassenger(id: number, data: {
      firstName: string;
      lastName: string;
      rut: string;
    }) {
      const newPassengers = [];
      for (let index = 0; index < this.passengers.length; index++) {
        if (index == id) {
          const element = this.passengers[index];
          element.firstName = data.firstName
          element.lastName = data.lastName
          element.rut = data.rut;
          newPassengers.push(element)
        } else {
          newPassengers.push(this.passengers[index]);
        }
      }
      this.passengers = newPassengers;
    },
    updateStageTrip(stage: STAGE_STEPS = 'outbound') {
      this.stageTrip = stage;
    },
    updateStageSeating(stage: STAGE_STEPS = 'outbound') {
      this.stageSeatings = stage
    },
    async getDrivers() {
      const data = await userApi.getAllDrivers();
      this.drivers = data.map((user) => {
        return {
          ...user,
          title: user.firstName + " " + user.lastName,
          value: user._id,
        };
      });
    },
    async getVehicles() {
      const data = await vehicleApi.getAllPatents();
      this.vehicles = data.map((dataVehicle) => {
        return {
          ...dataVehicle,
          title: dataVehicle.patent,
          value: dataVehicle._id
        }
      });
    },
    async getPlanningTrips() {
      const { items, total } = await planningTripAPI.getPlanningTrips({
        limit: this.paginationPlanningTrips.limit,
        page: this.paginationPlanningTrips.page,
      });
      this.planningTrips = items.map((item) => {
        return {
          ...item,
          driver: {
            ...item.driver,
            newValueEditable: item.driver._id,
          },
          allDays: false,
          nameCRUD: {
            newValueEditable: item.name,
            editable: false,
            loading: false,
          },
          stations: []
        };
      });
      this.totalPlanningTrips = total;
    },
    orderPrice() {
      this.tripsSelected = this.tripsSelected.sort(
        (a: GetBookingResponse, b: GetBookingResponse) => {
          return a.price - b.price;
        }
      );
    },
    orderTime(early = true) {
      this.tripsSelected = this.tripsSelected.sort(
        (a: GetBookingResponse, b: GetBookingResponse) => {
          const hourA = a.hourInit;
          const hourB = b.hourInit;
          if (hourA < hourB) {
            return early ? -1 : 1;
          }
          if (hourA > hourB) {
            return early ? 1 : -1;
          }
          return 0;
        }
      );
    },
    setPage(page: number) {
      this.paginationPlanningTrips.page = page;
    },
    setLimit(limit: number) {
      this.paginationPlanningTrips.limit = limit;
    },
    resetDaySearch() {
      if (!this.searchSelected) {
        return;
      }
      if (this.buyMassive) {
        this.searchSelected = {
          ...this.searchSelected,
          date: []
        }
        this.tripsSelected = []
      }
    },
    async getTripsOutbound(data: GetBookingRequest, date: number | number[], zone?:string) {
      this.tripsSelected = [];
      this.searchSelected = { ...data, date, zone };
      localStorage.setItem("searchSelected", JSON.stringify({...data,zone}));
      localStorage.setItem("isMassive", this.buyMassive + "")
      const trips = await planningTripAPI.getBooking({ ...data, date: typeof date === 'number' ? date : date.join(',') })
      this.tripsSelected = trips.sort(
        (a: GetBookingResponse, b: GetBookingResponse) => {
          const hourA = a.hourInit;
          const hourB = b.hourInit;
          if (hourA < hourB) {
            return -1;
          }
          if (hourA > hourB) {
            return 1;
          }
          return 0;
        }
      );
      this.emptyTrips = this.tripsSelected.length === 0;
    },
    async getTripsReturn(data: GetBookingRequest, date: number) {
      if (this.searchSelected) {
        this.searchSelected.dateFinal = date;
      }
      const tripsFinal = await planningTripAPI.getBooking({ ...data, date, origin: data.destination, destination: data.origin })
      this.tripsSelectedFinal = tripsFinal.sort(
        (a: GetBookingResponse, b: GetBookingResponse) => {
          const hourA = a.hourInit;
          const hourB = b.hourInit;
          if (hourA < hourB) {
            return -1;
          }
          if (hourA > hourB) {
            return 1;
          }
          return 0;
        }
      );
    },
    clearBooking() {
      this.tripsSelected = [];
      this.tripsSelectedFinal = [];
      this.searchSelected = undefined;
      localStorage.removeItem("tripSelected");
      localStorage.removeItem("tripFinalSelected");
      localStorage.removeItem("searchSelected");
      localStorage.removeItem("owner");
      localStorage.removeItem("passengers");
      localStorage.removeItem("passengersFinal");
      localStorage.removeItem("step");
      localStorage.removeItem('ownerFinal')
      localStorage.removeItem('isMassive')
      this.$reset();
    },
    setLoadingBooking(value: boolean) {
      this.isLoadingBooking = value;
    },
    removeSearchBooking() {
      this.searchSelected = undefined;
      this.tripsSelected = [];
    },
    setTrip(trip: GetBookingResponse) {
      if (this.stageTrip == "return") {
        this.tripFinalSelected = { ...trip };
        localStorage.setItem("tripFinalSelected", JSON.stringify(trip));
      } else if (this.stageTrip == "outbound" && trip) {
        this.tripSelected = { ...trip };
        localStorage.setItem("tripSelected", JSON.stringify(trip));
      }
    },
    removeTripSelected() {
      this.tripSelected = undefined;
    },
    setOwner(data: CustomerSeating) {
      if (this.stageSeatings === 'outbound') {
        localStorage.setItem("owner", JSON.stringify(data));
        this.owner = { ...data };
      } else {
        localStorage.setItem("ownerFinal", JSON.stringify(data));
        this.ownerFinal = { ...data }
      }


    },
    setPassengers(passengers: CustomerSeating[]) {
      if (this.stageSeatings == 'outbound') {
        localStorage.setItem("passengers", JSON.stringify(passengers));
        this.passengers = [...passengers];
        return;
      }
      localStorage.setItem("passengersFinal", JSON.stringify(passengers));
      this.passengersFinal = [...passengers];
    },
    setStep(step: STEP) {
      this.step = step;
      localStorage.setItem("step", step);
      if (step == "PAYMENT") {
        localStorage.setItem("passengers", JSON.stringify(this.passengers));
        localStorage.setItem("owner", JSON.stringify(this.owner));
      }
    },
    setLoadingPlanning(id: string, value: boolean) {
      this.planningTrips = this.planningTrips.map((planning) => {
        return {
          ...planning,
          loading: planning._id == id ? value : false,
        };
      });
    },
    setDatePlanningCalendar(date : number){
      this.datePlanningCalendar = date;
    },
    async getCalendar(id: string) {
      const data = await planningTripAPI.getCalendar(id, this.datePlanningCalendar);
      this.calendarData = data;
    },
    resetCalendar() {
      this.calendarData = [];
    },
    resetPlannings() {
      this.planningTrips = [];
      this.setPage(1);
      this.setLimit(5);
    },
    setAllDays(id: string, value: boolean) {
      this.planningTrips = this.planningTrips.map((planning) => {
        return {
          ...planning,
          allDays: planning._id === id ? value : planning.allDays,
        };
      });
    }, updateVehicleCRUD(
      id: string,
      action: "reset" | "editable" | "loading" = "editable",
      value?: boolean
    ) {
      this.planningTrips = this.planningTrips.map((planning) => {
        if (planning._id === id) {
          const vehiclePlanning = planning.vehicle;
          switch (action) {
            case "editable":
              vehiclePlanning.editable = true;
              vehiclePlanning.newValueEditable = vehiclePlanning.patent;
              break;
            case "reset":
              vehiclePlanning.editable = false;
              vehiclePlanning.newValueEditable = vehiclePlanning.patent;
              break;
            case "loading":
              vehiclePlanning.loading = value;
              break;
            default:
              break;
          }
          return {
            ...planning,
            vehicle: vehiclePlanning,
          };
        }
        return { ...planning };
      });
    },
    updateDriverCRUD(
      id: string,
      action: "reset" | "editable" | "loading" = "editable",
      value?: boolean
    ) {
      this.planningTrips = this.planningTrips.map((planning) => {
        if (planning._id === id) {
          const driverPlanning = planning.driver;
          switch (action) {
            case "editable":
              driverPlanning.editable = true;
              break;
            case "reset":
              driverPlanning.editable = false;
              driverPlanning.newValueEditable = driverPlanning._id;
              break;
            case "loading":
              driverPlanning.loading = value;
              break;
            default:
              break;
          }
          return {
            ...planning,
            driver: driverPlanning,
          };
        }
        return { ...planning };
      });
    },
    resetSearchTrip() {
      if (this.buyMassive) {
        this.tripsSelecteds = []
        return;
      }
      this.updateStageSeating('outbound')
      this.updateStageTrip('outbound')
      this.tripSelected = undefined;
      this.tripFinalSelected = undefined;
    },
    updateNameCRUD(
      id: string,
      action: "reset" | "editable" | "loading" = "editable",
      value?: boolean
    ) {
      this.planningTrips = this.planningTrips.map((planning) => {
        if (planning._id === id) {
          const nameCRUD = planning.nameCRUD;
          switch (action) {
            case "editable":
              nameCRUD.editable = true;
              break;
            case "reset":
              nameCRUD.editable = false;
              nameCRUD.newValueEditable = planning.name;
              break;
            case "loading":
              nameCRUD.loading = value;
              break;
            default:
              break;
          }
          return {
            ...planning,
            nameCRUD,
          };
        }
        return { ...planning };
      });
    },
  },
});
