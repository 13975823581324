export enum ROUTES_ENDPOINTS{
    GET_STATIONS = 'get-stations',
    CREATE_STATION = 'create-station',
    CREATE_ROUTE = 'create-route',
    GET_ROUTES = 'get-routes',
    GET_ROUTE_ID = 'get-route/',
    CREATE_ROUTE_STATIONS = 'create-route-stations',
    GET_ROUTE_NAME = 'get-route-byname/',
    EDIT_STATION = 'edit-station/',
    REMOVE_STATION = 'remove-station/',
    DELETE_ROUTE = 'delete-route/',
    EDIT_ROUTE = 'edit-route/',
    GET_ZONES = 'zone'
}