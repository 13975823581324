
import { Options, Vue } from "vue-class-component";
import Contact from "@/components/home/Contact.vue";
import { customScrollTo } from "@/utils";
import ImageRutaPiedraRoja from "@/assets/images/home/RutaPiedraRoja.jpg";
import ImageHorariosPiedraRoja from "@/assets/images/home/HorariosPiedraRoja.jpg";
import ImageRutaChamisero from "@/assets/images/home/RutaChamisero.jpg";
import ImageHorariosChamisero from "@/assets/images/home/HorarioChamisero.jpg";
import ImageRutaReserva from "@/assets/images/home/RutaReserva.jpg";
import ImageRutaAyresIda from "@/assets/images/home/RutaAyresIda.jpg";
import ImageRutaAyresVuelta from "@/assets/images/home/RutaAyresVuelta.jpg";
import ImageHorariosReserva from "@/assets/images/home/HorariosReserva.jpg";
import ImageHorariosAyres from "@/assets/images/home/HorariosAyres.jpg";
import Points from "@/assets/images/punto-subida/points.png";

@Options({
  name: "RutasHorario",
  components: {
    Contact,
  },
})
export default class RutasHorario extends Vue {
  public timeOut: number | undefined = undefined;
  public ImageRutaPiedraRoja = ImageRutaPiedraRoja;
  public ImageHorariosPiedraRoja = ImageHorariosPiedraRoja;
  public ImageRutaChamisero = ImageRutaChamisero;
  public ImageHorariosChamisero = ImageHorariosChamisero;
  public ImageRutaReserva = ImageRutaReserva;
  public ImageHorariosReserva = ImageHorariosReserva;
  public ImageRutaAyresIda = ImageRutaAyresIda;
  public ImageRutaAyresVuelta= ImageRutaAyresVuelta;
  public ImageHorariosAyres= ImageHorariosAyres;

  public Points = Points;
  mounted(): void {
    this.timeOut = setTimeout(() => {
      const router = this.$route;
      const hash = router.hash;
      if (hash) {
        customScrollTo(hash.slice(1), 80);
      }
    }, 200);
  }
  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }
}
