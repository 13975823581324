

import { RouteStationAPI } from '@/api/route-station';
import { useMainStore } from '@/store/modules/main';
import { StationCrud, useRoutesStore } from '@/store/modules/route';
import { getErrorCatch, requiredField, min } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import type { Header } from "vue3-easy-data-table";
import { ResponseCommonName } from '@/types/common/api';
import { CreateRouteStationsRequest } from '@/types/request/route';
import { FormVuetify } from '@/types/common/vuetify';
import { useZoneStore } from '@/store/modules/zones';

interface ItemsStations {
    origin: ResponseCommonName,
    destination: ResponseCommonName,
    keyOrder: number,
    duration: number
}

@Options({
    name: 'CreateZone',
    props: {
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    components: {
        ModalCommon
    }
})
export default class CreateZone extends Vue {
    public nameRoute = ''
    private routeAPI = new RouteStationAPI()
    public mainStore = useMainStore()
    public routeStore = useRoutesStore();
    public zoneStore = useZoneStore();
    public loadingCreateRoute = false;
    public isVisible!: boolean;
    public stationsSelecteds: ItemsStations[] = []
    public emptyStations = false;
    public stationSelected: string | null = null;
    public typeRouteSelected: string | null = null;
    public stationsOrigin: StationCrud[] = [];
    public stationsDestination: StationCrud[] = []
    public dataStation = {
        origin: undefined,
        destination: undefined,
        duration: '',
        order: ''
    }
    public errorStation = false;
    public messageErrorStation = ''
    public emptyStationsOrigin = false;

    public headers: Header[] = [
        { text: '#', value: 'keyOrder' },
        { text: "Origen", value: "origin.name" },
        { text: 'Destino', value: 'destination.name' },
        { text: 'Duración(min)', value: 'duration' },
        { text: '', value: 'actions' }
    ];

    get stations() {
        if (this.typeRouteSelected !== null) {
            if (this.typeRouteSelected == 'IDA') {
                return this.routeStore.stationsList.filter(({ _id }) => !this.stationsOrigin.find((stationOriginSelected) => stationOriginSelected._id == _id))
            }
            if (this.typeRouteSelected == 'RETORNO') {
                return this.routeStore.stationsList.filter(({ _id }) => !this.stationsDestination.find((stationOriginSelected) => stationOriginSelected._id == _id))
            }
        }
        return []
    }

    get typeRoute() {
        return [
            {
                _id: 'IDA',
                name: 'Ida'
            },
            {
                _id: 'RETORNO',
                name: 'Retorno'
            }
        ]
    }

    closeModalCreateRoute() {
        this.nameRoute = '';
        this.stationsOrigin = [];
        this.stationsDestination = [];
        this.typeRouteSelected = null;
        this.stationSelected = null;
        const form = this.$refs['formNameRoute'] as FormVuetify;
        if (!form) return;
        form.reset();
        this.$emit('closeModal');
    }

    async saveRoute() {
        const form = this.$refs['formNameRoute'] as FormVuetify;
        if (!form) return;
        const validate = await form.validate();
            if(this.stationsOrigin.length == 0){
                this.emptyStationsOrigin = true
                return;
            }
        if (validate.valid) {
            this.loadingCreateRoute = true;
            try {
                await this.routeAPI.createZone({
                    name : this.nameRoute,
                    destination : this.stationsDestination.map(({_id}) => _id),
                    origin : this.stationsOrigin.map(({_id}) => _id)
                })
                await this.zoneStore.getZones()
                this.closeModalCreateRoute()
            } catch (error) {
                getErrorCatch(error)
            }
            this.loadingCreateRoute = false;
        }
    }
    public requiredField = requiredField;
    public min = min

    private resetErrorStation() {
        this.errorStation = false;
        this.messageErrorStation = ''
    }

    async addStation() {
        this.resetErrorStation();
        const form = this.$refs['formStation'] as FormVuetify;
        if (!form) return;
        const validate = await form.validate();
        if (validate.valid && this.stationSelected !== null) {
            const findStation = this.stations.find(({ _id }) => _id == this.stationSelected);
            if (findStation) {
                if (this.typeRouteSelected == 'IDA') {
                    this.stationsOrigin.push(findStation)
                    this.emptyStationsOrigin = false;
                }
                if (this.typeRouteSelected == 'RETORNO') {
                    this.stationsDestination.push(findStation)
                }
                this.stationSelected = null;
                this.typeRouteSelected = null
            }
        }
    }

    deleteStation(id: string, type : 'IDA' | 'RETORNO' = 'IDA'){
        if(type == 'IDA'){
            this.stationsOrigin  = this.stationsOrigin.filter(({_id}) => _id !== id);
        }
        if(type == 'RETORNO'){
            this.stationsDestination  = this.stationsDestination.filter(({_id}) => _id !== id);
        }
        if(this.stationsOrigin.length == 0){
            this.emptyStationsOrigin = true;
        }
    }
}
