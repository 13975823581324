
import { convertTo12HourFormat, getDate } from '@/filters/formatDate';
import { usePlanningStore } from '@/store/modules/planning';
import { Options, Vue } from 'vue-class-component';
import { useRoutesStore } from '@/store/modules/route';
import Seating from '../Seating.vue';
import { useMainStore } from '@/store/modules/main';
import timonIcon from '@/assets/images/icons/timon.png'
import InfoBusSeatings from './InfoBusSeatings.vue';
import RouteBus from './RouteBus.vue';
import { CustomerSeating } from '@/types/request/planning';
import { customScrollTo, sleep } from '@/utils';

@Options({
    name: 'DetailBooking',
    components: {
        Seating,
        InfoBusSeatings,
        RouteBus
    }
})
export default class DetailBooking extends Vue {
    public planningStore = usePlanningStore();
    public routeStore = useRoutesStore();
    public timonIcon = timonIcon;
    public convertTo12HourFormat = convertTo12HourFormat;
    public getDate = getDate;
    public seatingSelected: string[] = []
    private mainStore = useMainStore()
    public timeOut = 0;

    get isMassive() {
        return this.planningStore.buyMassive
    }

    get searchSelected() {
        return this.planningStore.searchSelected;
    }

    get stageSeating() {
        return this.planningStore.stageSeatings
    }

    get tripSelected() {
        return this.planningStore.tripSelected;
    }

    get tripsSelecteds() {
        return this.planningStore.tripsSelecteds
    }

    get tripFinalSelected() {
        return this.planningStore.tripFinalSelected;
    }

    get days() {
        return this.planningStore.rangeDatesMassive;
    }

    get tripsSearch() {
        return this.planningStore.tripsSelected;
    }

    get seatingsTrip() {
        return this.tripSelected?.seatings || []
    }

    get seatingsTripFinal() {
        return this.tripFinalSelected?.seatings || []
    }

    get passengers() {
        let pass: string[] = []
        this.planningStore.owner && pass.push(this.planningStore.owner.seating);
        pass = [...pass, ...this.planningStore.passengers.map(({ seating }) => seating)].sort(this.sortSeatings)
        return pass
    }

    get daySeletedSeating() {
        return this.planningStore.daySelectedSeating
    }

    get selectedAllSeating() {
        return this.planningStore.selectedAllSeating
    }

    get typeDistribution() {
        if (this.isMassive) {
            if (this.daySeletedSeating !== '') {
                const searchTrip = this.tripsSelecteds.find(({ dateExecuting }) => dateExecuting.split("T")[0].split("-")[2] == this.daySeletedSeating)
                if (searchTrip) {
                    return searchTrip.vehicle.seatingsDistribution
                }
            }
            return 'DEFAULT'
        }
        if (!this.planningStore.tripSelected) return 'DEFAULT'
        if (this.stageSeating == 'return' && this.planningStore.tripFinalSelected) {
            return this.planningStore.tripFinalSelected.vehicle.seatingsDistribution
        }
        return this.planningStore.tripSelected.vehicle.seatingsDistribution
    }

    get seatingsAvailableMassive() {
        if (this.isMassive && this.selectedAllSeating) {
            const seatingsAvailable: { day: string, seating: number[] }[] = this.tripsSelecteds.map((trip) => {
                const day = trip.dateExecuting.split("T")[0].split("-")[2];
                const vehicleSeating = Array.from({ length: trip.vehicle.seatings }, (_, i) => i + 1)
                const seatingsBusy = trip.seatings.map(({ seating }) => parseInt(seating));
                return {
                    day: day,
                    seating: vehicleSeating.filter((seatingGen) => !seatingsBusy.includes(seatingGen))
                }
            });
            return seatingsAvailable.map(day => day.seating).reduce((acc, curr) => acc.filter(num => curr.includes(num)));
        }
        return []
    }

    created() {
        if (this.daySeletedSeating == '') {
            this.planningStore.setDaySelectedSeating('')
        }
        this.$watch('daySeletedSeating', (day) => {
            const tripSelected = this.tripsSelecteds.find(({ dateExecuting }) => {
                return dateExecuting.split("T")[0].split("-")[2] == day
            })
            if (tripSelected) {
                const seatings = tripSelected.seatingSelected;
                if (seatings && seatings.length > 0) {
                    this.seatingSelected = seatings
                }
            }
        })
    }

    toggleSeating(value: string) {
        const exist = this.searchSeating(value);
        if (exist) return;
        const existValue = this.seatingSelected.find((key) => key == value);
        if (existValue) {
            this.seatingSelected = this.seatingSelected.filter(key => key !== value);
            if (this.isMassive && this.daySeletedSeating) {
                if (!this.selectedAllSeating) {
                    this.planningStore.setSeatingTripMassive(value, this.daySeletedSeating, true);
                } else {
                    for (let index = 0; index < this.tripsSelecteds.length; index++) {
                        const element = this.tripsSelecteds[index];
                        const day = element.dateExecuting.split("T")[0].split("-")[2]
                        this.planningStore.setSeatingTripMassive(value, day,true);
                    }
                }
            }
        } else {
            if (this.stageSeating === 'return') {
                if (this.seatingSelected.length === this.passengers.length) {
                    this.mainStore.setNotification({
                        isOpen: true,
                        message: 'Ya no puedes seleccionar mas asientos.',
                        color: 'red darken-3'
                    })
                    return;
                }
            }
            if (this.isMassive) {
                if (this.daySeletedSeating) {
                    if (this.selectedAllSeating) {
                        for (let index = 0; index < this.tripsSelecteds.length; index++) {
                            const element = this.tripsSelecteds[index];
                            const day = element.dateExecuting.split("T")[0].split("-")[2]
                            this.planningStore.setSeatingTripMassive(value, day);
                        }
                    } else {
                        this.planningStore.setSeatingTripMassive(value, this.daySeletedSeating);
                    }
                }
            }
            this.seatingSelected.push(value);
        }
        this.seatingSelected = this.seatingSelected.sort(this.sortSeatings);
    }


    sortSeatings(a: string, b: string) {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    }

    searchSeating(value: string) {
        if (this.isMassive) {
            if (this.selectedAllSeating) {
                return !this.seatingsAvailableMassive.includes(parseInt(value));
            }
            const searchTrip = this.tripsSelecteds.find(({ dateExecuting }) => dateExecuting.split("T")[0].split("-")[2] == this.daySeletedSeating)
            if (searchTrip) {
                const seatings = searchTrip.seatings.map(({ seating }) => seating)
                return seatings.includes(value)
            }
            return false;
        }
        if (this.stageSeating == 'outbound') {
            return !!this.seatingsTrip.find(({ seating }) => value === seating)
        } else if (this.stageSeating === 'return') {
            return !!this.seatingsTripFinal.find(({ seating }) => value === seating)
        }
    }
    backStepSeating() {
        this.seatingSelected = [];
        this.planningStore.updateStageSeating('outbound');
        this.planningStore.setPassengers([]);
    }

    async nextStep() {
        if (this.stageSeating == 'return') {
            if (this.seatingSelected.length !== this.passengers.length) {
                this.mainStore.setNotification({
                    isOpen: true,
                    message: 'Falta asientos por seleccionar, deben ser la misma cantidad que la ida.',
                    color: 'red-darken-2',
                    timeout: 3000
                })
                return;
            }
        }
        this.planningStore.setOwner({
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            rut: '',
            seating: this.seatingSelected[0]
        })
        const seatings: CustomerSeating[] = [];
        this.seatingSelected.forEach((value, index) => {
            if (index > 0) {
                seatings.push({
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    rut: '',
                    seating: value,
                    document : 'RUT'
                })
            }
        });
        console.log(seatings)
        this.planningStore.setPassengers(seatings);
        if (this.searchSelected?.typeTrip === 'round') {
            if (this.stageSeating === 'return') {
                this.planningStore.setStep('PASSENGERS');
                await sleep(300, this.timeOut);
                customScrollTo('null', 0)
            } else {
                this.seatingSelected = [];
                this.mainStore.setNotification({
                    isOpen: true,
                    message: 'Seleccione tus asientos del viaje de vuelta.',
                    color: 'green darken-2'
                })
                await sleep(300, this.timeOut);
                customScrollTo('null', 0)
            }
            this.planningStore.updateStageSeating('return');
        } else {
            this.planningStore.setStep('PASSENGERS');
            await sleep(300, this.timeOut);
            customScrollTo('null', 0)
        } 
    }

    setDaySeating(day: string) {
        this.seatingSelected = []
        this.planningStore.setDaySelectedSeating(day)
    }

    setAllDays(value: boolean) {
        this.seatingSelected = []
        this.planningStore.setSelectedAllSeatings(value)
    }
}
